.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(80, 80, 80);
  height: 80px;
  z-index: 1000;
  font-family: 'Slackey';
}

.banner_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toadPic {
  width: 72px;
}

.banner_logo {
  margin-left: 1rem;
}

.banner_logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.banner_spacer {
  flex: 1;
}

.banner_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.banner_navigation-items li {
  padding: 0 0.5rem;
}

.banner_navigation-items a {
  color: white;
  text-decoration: none;
}

.banner_navigation-items a:hover,
.banner_navigation-items a:active {
  color: #fa923f;
}

@media (max-width: 768px) {
  .banner_navigation-items {
    display: none;
  }
  .toadPic {
    padding-left: 30px;
  }
}

@media (min-width: 769px) {
  .banner_toggleButton {
    display: none;
  }

  .banner_logo {
    margin-left: 0;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .banner {
    font-size: 1.2rem;
  }
}
