.About_wrapper {
  margin: 0 5px;
}

.About_wrapper p {
  margin: 10px 0;
}

.About_header {
  font-family: Slackey;
  text-align: center;
}

.About_housePic {
  width: 250px;
  float: right;
  margin: 10px;
}

.About_casualParagraph {
  padding: 10px 0;
}

.About_toadPic {
  width: 150px;
  float: left;
  margin: 10px;
}

.About_plaquePic {
  width: 150px;
  float: left;
  margin: 10px;
}

.About_shirtPic {
  width: 300px;
  float: right;
  margin: 10px;
}

.About_mugPic {
  width: 200px;
  float: left;
  margin: 10px;
}

/*---BIG SCREENS---*/
@media (min-width: 1000px) {
  .About_wrapper p {
    font-size: 1.2rem;
  }
  .About_header {
    font-size: 2.5rem;
  }
  .About_housePic {
    width: 300px;
  }
  .About_toadPic {
    width: 180px;
  }
  .About_plaquePic {
    width: 180px;
  }
  .About_shirtPic {
    width: 350px;
  }
  .About_mugPic {
    width: 225px;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .About_wrapper p {
    font-size: 1.5rem;
  }
  .About_header {
    font-size: 3rem;
  }
  .About_housePic {
    width: 350px;
  }
  .About_toadPic {
    width: 240px;
  }
  .About_plaquePic {
    width: 240px;
  }
  .About_shirtPic {
    width: 400px;
  }
  .About_mugPic {
    width: 325px;
  }
}
