.Stats_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.Stats_wrapper p {
  margin: 10px 0;
}

.Stats_wrapper ul {
  list-style-type: none;
}

.Stats_header {
  font-family: Slackey;
  text-align: center;
}

.Stats_about {
  grid-column-start: 1;
  grid-column-end: span 6;
  margin: 10px;
}

.Stats_results {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2017 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2018 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2019 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2020 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2021 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2022 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Stats_2023 {
  grid-column-start: 1;
  grid-column-end: span 6;
}

/*---BIG SCREENS---*/
@media (min-width: 1000px) {
  .Stats_wrapper p {
    font-size: 1.2rem;
  }
  .Stats_header {
    font-size: 2.5rem;
  }
  .Stats_nameList {
    font-size: 1.2rem;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .Stats_wrapper p {
    font-size: 1.5rem;
  }
  .Stats_header {
    font-size: 3rem;
  }
  .Stats_nameList {
    font-size: 1.4rem;
  }
  .Stats_about {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .Stats_results {
    grid-column-start: 4;
    grid-column-end: span 3;
  }

  .Stats_2017 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .Stats_2018 {
    grid-column-start: 3;
    grid-column-end: span 2;
  }

  .Stats_2019 {
    grid-column-start: 5;
    grid-column-end: span 2;
  }

  .Stats_2020 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .Stats_2021 {
    grid-column-start: 3;
    grid-column-end: span 2;
  }

  .Stats_2022 {
    grid-column-start: 5;
    grid-column-end: span 2;
  }

  .Stats_2023 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

}
