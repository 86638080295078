.Wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  height: 100%;
  min-width: 900px;
  background-color: ivory;
}

.Header {
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: center;
  font-size: larger;
  font-weight: bold;
  margin: 5px 0 10px 0;
}

.SelectPlayer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-start: 1;
  grid-column-end: -1;
  margin: 5px 0 10px 0;
}

.Instructions {
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 10px;
  background-color: lightcoral;
}

.DropdownContainer {
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 10px;
  background-color: moccasin;
}

.DNDWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DropArea {
  margin: 8px;
}

.DroppableItemsWrapper {
  background-color: burlywood;
  padding: 12px;
  min-width: 200px;
  min-height: 100px;
}

.DraggableItem {
  display: flex;
  border: 2px solid black;
  user-select: 'none';
  margin: 8px 8px 8px 8px;
  padding: 5px;
  background-color: khaki;
}

.DraggableItemVetoed {
  display: flex;
  border: 2px solid black;
  user-select: 'none';
  margin: 8px 8px 8px 8px;
  padding: 5px;
  background-color: red;
}

.GameBox {
  height: 30px;
}

.GameTitle {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 0 0 8px;
  margin: auto;
  text-align: center;
}

.SubmitButton {
  font-size: large;
  font-weight: bold;
  grid-column-start: 2;
  grid-column-end: 4;
  cursor: pointer;
}

.RefreshButtonWrapper {
  width: 60px;
  background-color: green;
  text-align: center;
}

.RefreshButton {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .GameTitle {
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0 0 0 8px;
    margin: auto;
    text-align: center;
  }

  .SelectPlayer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-start: 1;
    grid-column-end: -1;
    margin: 5px 0 10px 0;
  }

  .Instructions {
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 10px;
    background-color: lightcoral;
  }

  .DropdownContainer {
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 10px;
    background-color: moccasin;
  }
}
