/*--- iOS Landing Page Fix --*/
.IOS_landing {
  position: relative;
  width: 100%;
  height: calc(90vh - 100px);
  display: table;
  z-index: -1;
}
.IOS_wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.IOS_inner {
  background-image: url('../images/homepageimage.jpg');
  position: fixed;
  display: table;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.Home_wrapper {
  width: 100%;
}

.Home_wrapper a {
  color: darkblue;
  text-decoration: none;
}

.Home_scrollPanel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  top: -10vh;
  background-color: white;
  padding: 20px 10px;
}

.Home_scrollPanelHeader {
  grid-column-start: 1;
  grid-column-end: span 6;
  font-size: 2.5rem;
}

.Home_scrollDown {
  grid-column-start: 1;
  grid-column-end: span 6;
  text-align: center;
  font-size: 1.5rem;
  margin: -12px 0 150px 0;
}

/* IMAGE LINKS */
.Home_upcoming {
  grid-column-start: 1;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}
.Home_upcomingPic {
  width: 300px;
}
.Home_stats {
  grid-column-start: 3;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}
.Home_statsPic {
  width: 300px;
}
.Home_reports {
  grid-column-start: 5;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}
.Home_reportsPic {
  width: 225px;
}

/* TEXT LINKS */
.Home_upcomingText {
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 2rem;
}
.Home_statsText {
  grid-column-start: 3;
  grid-column-end: span 2;
  font-size: 2rem;
}
.Home_reportsText {
  grid-column-start: 5;
  grid-column-end: span 2;
  font-size: 2rem;
}

/* ABOUT US */
.Home_groupPic {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 30px 0;
}
.Home_groupPicImage {
  width: 100%;
}
.Home_groupText {
  grid-column-start: 4;
  grid-column-end: span 1;
  padding: 10px 20px;
  text-align: center;
  margin: auto;
}
.Home_toadPic {
  grid-column-start: 5;
  grid-column-end: span 2;
  text-align: center;
  margin: auto;
}
.Home_toptoadImage {
  width: 75%;
}

/* WHAT PLAYERS ARE SAYING */
.Home_playerChatHeader {
  grid-column-start: 1;
  grid-column-end: span 6;
  font-size: 2.5rem;
  margin: 10px 0 15px 0;
}
.Home_playerChat2017 {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2017 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2018 {
  grid-column-start: 4;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2018 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2019 {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2019 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2020 {
  grid-column-start: 4;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2020 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2021 {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2021 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2022 {
  grid-column-start: 4;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2022 {
  width: 90%;
  margin: auto;
}
.Home_playerChat2023 {
  grid-column-start: 1;
  grid-column-end: span 3;
  margin: 0 0 30px 0;
  text-align: center;
}
.Home_playerChatImage2023 {
  width: 90%;
  margin: auto;
}
.Home_playerChatCaptions {
  font-style: italic;
}

/*---SMALL SCREENS---*/
@media (max-width: 1000px) {
  .IOS_landing {
    height: calc(75vh - 100px);
  }
  .Home_upcomingPic {
    width: 200px;
    margin: auto;
  }
  .Home_statsPic {
    width: 200px;
    margin: auto;
  }
  .Home_reportsPic {
    width: 150px;
    margin: auto;
  }
  .Home_groupPic {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  .Home_upcomingText {
    font-size: 1.5rem;
  }
  .Home_statsText {
    font-size: 1.5rem;
  }
  .Home_reportsText {
    font-size: 1.5rem;
  }
  .Home_groupText {
    grid-column-start: 3;
    grid-column-end: span 2;
  }
}

/*---VERY SMALL SCREENS---*/
@media (max-width: 768px) {
  .Home_scrollPanelHeader {
    font-size: 2rem;
  }
  .Home_scrollDown {
    font-size: 1.3rem;
  }
  /* IMAGE LINKS */
  .Home_upcoming {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_stats {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_reports {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_groupPic {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_groupText {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin-bottom: 30px;
    text-align: center;
  }
  .Home_toadPic {
    display: none;
  }
  .Home_playerChat2017 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2018 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2019 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2020 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2021 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2022 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Home_playerChat2023 {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
}

/*---BIG SCREENS---*/
@media (min-width: 1200px) {
  .Home_groupText {
    font-size: 1.2rem;
  }
  .Home_playerChatCaptions {
    font-size: 1.3rem;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .Home_groupText {
    font-size: 1.5rem;
  }
  .Home_playerChat2017 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  .Home_playerChat2018 {
    grid-column-start: 3;
    grid-column-end: span 2;
  }
  .Home_playerChat2019 {
    grid-column-start: 5;
    grid-column-end: span 2;
  }
  .Home_playerChat2020 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  .Home_playerChat2021 {
    grid-column-start: 3;
    grid-column-end: span 2;
  }
  .Home_playerChat2022 {
    grid-column-start: 5;
    grid-column-end: span 2;
  }
  .Home_playerChat2023 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  .Home_playerChatCaptions {
    font-size: 1.3rem;
  }
}
