.Upcoming_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.Upcoming_wrapper p {
  margin: 10px 0;
}

.Upcoming_wrapper ul {
  list-style-type: none;
}

.Upcoming_banner {
  grid-column-start: 1;
  grid-column-end: span 2;
  background-color: burlywood;
  padding-bottom: 10px;
}

.Upcoming_toadTips {
  grid-column-start: 1;
  grid-column-end: span 2;
  background-color: beige;
  padding: 10px;
}

.Upcoming_theToads {
  grid-column-start: 1;
  grid-column-end: span 2;
  text-align: center;
  background-color: darkseagreen;
  padding: 10px;
}

.Upcoming_theGames {
  grid-column-start: 1;
  grid-column-end: span 2;
  text-align: center;
  background-color: lightsalmon;
  padding: 10px;
}

.Upcoming_theEats {
  grid-column-start: 1;
  grid-column-end: span 2;
  text-align: center;
  background-color: plum;
  padding: 10px;
}

.Upcoming_theEats_day1,
.Upcoming_theEats_day2,
.Upcoming_theEats_day3,
.Upcoming_theEats_day4 {
  padding-bottom: 30px;
}

.Upcoming_header {
  font-family: Slackey;
  text-align: center;
}

.Upcoming_biggerText {
  font-size: 1.5rem;
}

.Upcoming_antandPic {
  width: 400px;
}

/*---BIG SCREENS---*/
@media (min-width: 1000px) {
  .Upcoming_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .Upcoming_wrapper p {
    font-size: 1.2rem;
  }
  .Upcoming_header {
    font-size: 2.5rem;
  }
  .Upcoming_banner {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  .Upcoming_toadTips {
    grid-column-start: 1;
    grid-column-end: span 1;
  }

  .Upcoming_theToads {
    grid-column-start: 2;
    grid-column-end: span 1;
  }

  .Upcoming_theToads_nameList {
    font-size: 1.2rem;
    padding-top: 10px;
  }

  .Upcoming_theGames {
    grid-column-start: 1;
    grid-column-end: span 1;
    font-size: 1.4rem;
  }

  .Upcoming_theEats {
    grid-column-start: 2;
    grid-column-end: span 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 1.2rem;
  }

  .Upcoming_theEats_header {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  .Upcoming_theEats_day1 {
    grid-column-start: 1;
    grid-column-end: span 1;
    padding-bottom: 30px;
  }
  .Upcoming_theEats_day2 {
    grid-column-start: 2;
    grid-column-end: span 1;
    padding-bottom: 30px;
  }
  .Upcoming_theEats_day3 {
    grid-column-start: 1;
    grid-column-end: span 1;
  }
  .Upcoming_theEats_day4 {
    grid-column-start: 2;
    grid-column-end: span 1;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .Upcoming_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .Upcoming_wrapper p {
    font-size: 1.5rem;
  }
  .Upcoming_header {
    font-size: 3rem;
  }
  .Upcoming_banner {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .Upcoming_toadTips {
    grid-column-start: 1;
    grid-column-end: span 1;
  }

  .Upcoming_theToads {
    grid-column-start: 2;
    grid-column-end: span 1;
  }

  .Upcoming_theToads_nameList {
    font-size: 1.4rem;
    padding-top: 10px;
  }

  .Upcoming_theGames {
    grid-column-start: 3;
    grid-column-end: span 1;
  }

  .Upcoming_theEats {
    grid-column-start: 1;
    grid-column-end: span 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 1.4rem;
  }

  .Upcoming_theEats_header {
    grid-column-start: 1;
    grid-column-end: span 4;
  }
  .Upcoming_theEats_day1 {
    grid-column-start: 1;
    grid-column-end: span 1;
  }
  .Upcoming_theEats_day2 {
    grid-column-start: 2;
    grid-column-end: span 1;
  }
  .Upcoming_theEats_day3 {
    grid-column-start: 3;
    grid-column-end: span 1;
  }
  .Upcoming_theEats_day4 {
    grid-column-start: 4;
    grid-column-end: span 1;
  }
}
