.Reports_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-size: 2rem;
  font-family: Slackey;
  text-align: center;
  align-items: center;
}

.Reports_wrapper a {
  color: steelblue;
  text-decoration: none;
}

.Reports_header {
  grid-column-start: 1;
  grid-column-end: span 6;
  text-align: center;
  font-size: 2.5rem;
  padding: 10px 0 30px 0;
}

.Reports_2017 {
  grid-column-start: 1;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2018 {
  grid-column-start: 3;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2019 {
  grid-column-start: 5;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2020 {
  grid-column-start: 1;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2021 {
  grid-column-start: 3;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2022 {
  grid-column-start: 5;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_2023 {
  grid-column-start: 1;
  grid-column-end: span 2;
  margin: 0 auto 20px auto;
}

.Reports_pics {
  width: 95%;
}

/*---SMALL SCREENS---*/
@media (max-width: 1000px) {
  .Reports_2017 {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .Reports_2018 {
    grid-column-start: 4;
    grid-column-end: span 3;
  }

  .Reports_2019 {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .Reports_2020 {
    grid-column-start: 4;
    grid-column-end: span 3;
  }

  .Reports_2021 {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .Reports_2022 {
    grid-column-start: 4;
    grid-column-end: span 3;
  }

  .Reports_2023 {
    grid-column-start: 1;
    grid-column-end: span 3;
  }
}

/*---VERY SMALL SCREENS---*/
@media (max-width: 768px) {
  .Reports_2017 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2018 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2019 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2020 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2021 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2022 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }

  .Reports_2023 {
    grid-column-start: 1;
    grid-column-end: span 6;
    margin: 0 auto 40px auto;
  }
}

/*---BIG SCREENS---*/
@media (min-width: 1200px) {
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .Reports_wrapper {
    font-size: 2.5rem;
  }
  .Reports_header {
    font-size: 3rem;
  }
}
