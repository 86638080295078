.Report20XX_wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  text-align: center;
  align-items: center;
}

.Report20XX_wrapper ul {
  list-style-type: none;
}

.Report20XX_header {
  grid-column-start: 1;
  grid-column-end: span 6;
  font-size: 2.5rem;
  padding: 10px 0 30px 0;
  font-family: Slackey;
}

.Report20XX_champPlaque {
  grid-column-start: 1;
  grid-column-end: span 2;
}

.Report20XX_champText {
  grid-column-start: 3;
  grid-column-end: span 2;
  font-size: 1.5rem;
}

.Report20XX_champTextShrink {
  grid-column-start: 3;
  grid-column-end: span 2;
  font-size: 0.9rem;
}

.Report20XX_champPic {
  grid-column-start: 5;
  grid-column-end: span 2;
}

.Report20XX_champPlaquePic {
  width: 90%;
}

.Report20XX_champPlayerPic {
  width: 90%;
}

.Report20XX_toads {
  grid-column-start: 1;
  grid-column-end: span 4;
}

.Report20XX_toadsText {
  grid-column-start: 5;
  grid-column-end: span 2;
  font-size: 1.2rem;
}

.Report20XX_toadsPic,
.Report20XX_gamesPic {
  width: 90%;
}

.Report20XX_gamesText {
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 1rem;
}

.Report20XX_games {
  grid-column-start: 3;
  grid-column-end: span 4;
}

.Report20XX_extraPics {
  margin-top: 10px;
  grid-column-start: 1;
  grid-column-end: span 6;
}

.Report20XX_extraPic1,
.Report20XX_extraPic2,
.Report20XX_extraPic3,
.Report20XX_extraPic4,
.Report20XX_extraPic5,
.Report20XX_extraPic6,
.Report20XX_extraPic7,
.Report20XX_extraPic8,
.Report20XX_extraPic9,
.Report20XX_extraPic10,
.Report20XX_extraPic11,
.Report20XX_extraPic12,
.Report20XX_extraPic13,
.Report20XX_extraPic14,
.Report20XX_extraPic15,
.Report20XX_extraPic16 {
  padding: 20px;
  width: 50%;
}

.Report20XX_padding {
  padding: 2rem;
}

/*---SMALL SCREENS---*/
@media (max-width: 1000px) {
  .Report20XX_champText {
    font-size: 1rem;
  }
  .Report20XX_toadsText {
    font-size: 1rem;
  }
  .Report20XX_gamesText {
    font-size: 0.8rem;
  }
}

/*---VERY SMALL SCREENS---*/
@media (max-width: 768px) {
  .Report20XX_champText,
  .Report20XX_champTextShrink,
  .Report20XX_champPic,
  .Report20XX_toads,
  .Report20XX_toadsText,
  .Report20XX_gamesText,
  .Report20XX_games {
    grid-column-start: 1;
    grid-column-end: span 6;
  }
  .Report20XX_champPlaque {
    display: none;
  }
  .Report20XX_champText,
  .Report20XX_champTextShrink,
  .Report20XX_toadsText,
  .Report20XX_gamesText {
    font-size: 1rem;
    margin-bottom: 8px;
  }
  .Report20XX_padding {
    padding: 1rem;
  }
  .Report20XX_header {
    padding: 10px 0 0px 0;
  }
  .Report20XX_extraPic1,
  .Report20XX_extraPic2,
  .Report20XX_extraPic3,
  .Report20XX_extraPic4,
  .Report20XX_extraPic5,
  .Report20XX_extraPic6,
  .Report20XX_extraPic7,
  .Report20XX_extraPic8,
  .Report20XX_extraPic9,
  .Report20XX_extraPic10,
  .Report20XX_extraPic11,
  .Report20XX_extraPic12,
  .Report20XX_extraPic13,
  .Report20XX_extraPic14,
  .Report20XX_extraPic15,
  .Report20XX_extraPic16 {
    padding: 20px;
    width: 100%;
  }
}

/*---BIG SCREENS---*/
@media (min-width: 1200px) {
  .Report20XX_champTextShrink {
    font-size: 1rem;
  }
  .Report20XX_gamesText {
    font-size: 1.2rem;
  }
}

/*---HUGE SCREENS---*/
@media (min-width: 1600px) {
  .Report20XX_wrapper {
    font-size: 2.5rem;
  }
  .Report20XX_header {
    font-size: 3rem;
  }
  .Report20XX_toadsText {
    font-size: 1.5rem;
  }
  .Report20XX_gamesText {
    font-size: 1.5rem;
  }
  .Report20XX_champTextShrink {
    font-size: 1.2rem;
  }
}
